<template>
	<div class="learn">
		<van-nav-bar
			title="學術中心"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		<div class="learn-wrap">
			<div class="learn-header">
				<van-image class="learn-header-img" :src="require('@/assets/images/learn-header.png')"></van-image>
			</div>
			<div class="learn-content">
				<div class="learn-item-one">
					<div class="one-header">
						<div class="one-title">我們的大數據</div>
						<div class="one-header-desc"></div>
					</div>
					<div class="list-item">
						<div class="item-left">
							<van-image class="item-icon" :src="require('@/assets/images/learn-data-big.png')"></van-image>
							<div class="item-left-title">數據龐大</div>
						</div>
						<div class="item-right">超過2500萬華人，全人口、全世代，
												<br>持續15-30年的醫療健康數據，
												<br>包括醫院、體檢、公共衛生等全面數據</div>
					</div>
					<div class="list-item">
						<div class="item-left">
							<van-image class="item-icon" :src="require('@/assets/images/learn-data-widely.png')"></van-image>
							<div class="item-left-title">覆蓋面廣</div>
						</div>
						<div class="item-right">涵蓋健康、亞健康人群的
												<br>醫療健康數據，生老病死、疾病發展、
												<br>用藥就診全覆蓋</div>
					</div>
					<div class="list-item">
						<div class="item-left">
							<van-image class="item-icon" :src="require('@/assets/images/learn-data-guide.png')"></van-image>
							<div class="item-left-title">專業引領者</div>
						</div>
						<div class="item-right">研發團隊帶頭人黃教授，
												<br>曾任臺北醫學大學副教授、兩任（14年）
												<br>臺灣省“衛生署統計中心”主任</div>
					</div>
					<div class="list-item">
						<div class="item-left">
							<van-image class="item-icon" :src="require('@/assets/images/learn-data-major.png')"></van-image>
							<div class="item-left-title">專業研發團隊</div>
						</div>
						<div class="item-right">碩士、博士組成的研發團隊分組開發
												<br>引擎算灋，內部組隊PK後取最優成果</div>
					</div>
					<div class="list-item">
						<div class="item-left">
							<van-image class="item-icon" :src="require('@/assets/images/learn-data-sensitive.png')"></van-image>
							<div class="item-left-title">算灋敏感</div>
						</div>
						<div class="item-right">所用引擎算灋回歸千萬級華人全人口
												<br>醫療健康數據庫進行回溯驗證，
												<br>算灋敏感度及特异度均高於95%方採用</div>
					</div>
				</div> 
				<div class="learn-item-two">
					<div class="two-header">
						<div class="two-title">真實案例數據對比過程</div>
						<div class="two-header-desc"></div>
					</div>
					<div class="two-item">
						<div class="two-item-left">
							<div class="two-item-sort">1</div>
							<div class="two-item-jiantou"></div>
						</div>
						<div class="two-item-right">
							<div class="right-title">數據採集</div>
							<div class="right-text">數據採集時間：2021年8月10日、8月17日、8月23日
													<br>病例數量：100例（隨機抽取）</div>
						</div>
					</div>
					<div class="two-item">
						<div class="two-item-left">
							<div class="two-item-sort">2</div>
							<div class="two-item-jiantou"></div>
						</div>
						<div class="two-item-right">
							<div class="right-title">生成健康大數據分析與預測報告</div>
							<div class="right-text">比對各個病例的報告結果和診斷記錄，統計米因健康大數據算灋的準確度和有效性</div>
						</div>
					</div>
					<div class="two-item">
						<div class="two-item-left">
							<div class="two-item-sort">3</div>
						</div>
						<div class="two-item-right">
							<div class="right-title">報告結果與寓醫館歷年診斷記錄進行比對</div>
							<div class="right-text">米因通過採集回來的病例數據資訊，生成對應病例的大數據分析與預測報告</div>
						</div>
					</div>
				</div>
				<div class="learn-item-two">
					<div class="three-header">
						<div class="three-title">真實案例數據對比</div>
						<div class="three-header-desc"></div>
					</div>
					<div class="three-desc">示例：以17246號病例在寓醫館2012-2020年的診斷結果
										  <br>與2012年的米因大數據分析與預測報告結果進行比對</div>
					<div class="three-list">
						<div class="three-item">
							<div class="item-header">米因健康大數據分析與預測的疾病情况</div>
							<div class="item-content">
								<div class="item-cell">糖尿病、疼痛、高血脂、高血壓、肝炎、</div>
								<div class="item-cell">睡眠障礙、病毒性肝炎及慢性肝病、痛風、</div>
								<div class="item-cell">背脊椎病變、關節病變、泌尿系統疾病</div>
							</div>
						</div>
						<div class="three-item">
							<div class="item-header">寓醫館確診記錄</div>
							<div class="item-content padding-bottom-0">
								<div class="item-cell">2014-2015年確診患空腹血糖損害</div>
								<div class="item-cell">病人存在背脊椎和關節病變，囙此必然伴隨疼痛</div>
								<div class="item-cell">2012-2020年確診患高脂血症</div>
								<div class="item-cell">2012年確診脂肪肝伴輕度肝損，</div>
								<div class="item-cell">至2018年發展為中度脂肪肝並持續至今，</div>
								<div class="item-cell2">......</div>
								<div class="item-cell3"><span @click="handleToDetail">查看詳情 >></span></div>
							</div>
						</div>
					</div>
				</div>
				<div class="learn-last">
					<div class="last-one">
						米因—寓醫館真實案例
						<br>數據對比重疊率平均值為91.5%
					</div>
					<div class="last-desc">所有案例中，最低的重疊率也在50%以上，
										   <br>重疊率100%的案例共計50例，占比達到50%；
										   <br>65歲以上的老年案例7例，重疊率均為100%；</div>
				</div>
				
				<div class="footer">所有解釋權歸上海米因大數據科技有限公司所有</div>
				
			</div>
		</div>
		<!-- 底部导航 -->
		<van-tabbar 
			v-model="navIcon.active"
			active-color="#6681FA"
			inactive-color="#9DA6B9"
			safe-area-inset-bottom
		>
			<van-tabbar-item name="home" to="/">
				<span>首頁</span>
				<img 
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.home.active:navIcon.home.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="study" to="/study">
				<span>米因研究</span>
				<img
					style="width: 0.37rem;height: 0.43rem;"
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.study.active:navIcon.study.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="learn" to="/learn">
				<span>學術中心</span>
				<img
					style="width: 0.37rem;height: 0.43rem;"
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.learn.active:navIcon.learn.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="user" :to="isPerfectInfo ? '/user' : '/regist/perfectInfo'">
				<span>我的</span>
				<img
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.user.active:navIcon.user.normal"
				>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Image, Tabbar, TabbarItem } from 'vant';
Vue.use(NavBar)
   .use(Tabbar)
   .use(TabbarItem)
   .use(Image);


// import { isPay, studyList } from '@/js/axios';

export default {
	name: 'learn',
	data() {
		return {
			navIcon: {
				active: 'learn',
				home: {
					normal: require('@/assets/images/shouye1.png'),
					active: require('@/assets/images/shouye.png')
				},
				study: {
					normal: require('@/assets/images/study.png'),
					active: require('@/assets/images/study_active.png')
				},
				learn: {
					normal: require('@/assets/images/learn.png'),
					active: require('@/assets/images/learn-active.png')
				},
				user: {
					normal: require('@/assets/images/wode.png'),
					active: require('@/assets/images/wode2.png')
				}
			},
			isPerfectInfo: true,
		};
	},
	beforeDestroy() {
		
	},
	mounted() {
		if(this.$store.state.isLogin) {
			let userInfo = JSON.parse(localStorage.getItem('user'))
			if(userInfo && userInfo.birth != '' && userInfo.sex != 0) {
				this.isPerfectInfo = true
			} else {
				this.isPerfectInfo = false
			}	
		}
	},
	methods: {
		handleToDetail() {
			window.open('https://mp.weixin.qq.com/s/mf-nUYdzfSbL-amxwrzSdg')
		}
	}
};
</script>


<style>
	.back-icon {
		width: 0.42rem;
		height: 0.42rem;
	}
	.learn-wrap {
		padding-top: 0.88rem;
	}
	.learn-header {
		width: 100%;
		height: 6.6rem;
	}
	.learn-header-img {
		width: 100%;
		height: 6.6rem;
	}
	.learn-content {
		width: 100%;
		padding-bottom: 60px;
		/* padding-top: 0.8rem; */
		background-color: #121568;
	}
	.learn-item-one {
		padding: 0 0.5rem;
	}
	.one-header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		padding-left: 0.19rem;
		/* justify-content: center; */
	}
	.one-title {
		width: 3.37rem;
		height: 1.11rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.34rem;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #F2CF5F;
		background-image: url(../../assets/images/our-data.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.one-header-desc {
		width: 2.51rem;
		height: 0.5rem;
		margin-left: 0.12rem;
		background-image: url(../../assets/images/our-big-data.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-bottom: 0.1rem;
	}
	
	.learn-item-one .list-item {
		display: flex;
		align-items: center;
		width: 100%;
		/* padding: 0 0.32rem; */
		margin-top: 0.46rem;
	}
	.learn-item-one .item-left {
		width: 1.7rem;
		/* text-align: left; */
	}
	.learn-item-one .item-icon {
		width: 0.88rem;
		height: 0.88rem;
	}
	.learn-item-one .item-left-title {
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #43E9FF;
		line-height: 0.40rem;
	}
	.learn-item-one .item-right{
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		line-height: 0.44rem;
		margin-left: 0.12rem;
		text-align: left;
		flex: 1;
		letter-spacing: -1px;
	}
	.learn-item-two {
		padding: 0 0.69rem;
		margin-top: 0.72rem;
	}
	.two-header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		/* justify-content: center; */
		margin-bottom: 0.34rem;
	}
	.two-title {
		width: 4.77rem;
		height: 1.12rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.34rem;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #F2CF5F;
		background-image: url(../../assets/images/learn-case2.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.two-header-desc {
		width: 1.76rem;
		height: 0.5rem;
		margin-left: 0.12rem;
		background-image: url(../../assets/images/learn-process.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-bottom: 0.1rem;
	}
	.two-item {
		width: 100%;
		display: flex;
		align-items: flex-start;
		/* padding: 0 0.5rem; */
	}
	.two-item-sort {
		width: 0.48rem;
		height: 0.48rem;
		font-size: 0.28rem;
		font-family: Helvetica;
		color: #fff;
		line-height: 0.5rem;
		border-radius: 50%;
		background: linear-gradient(321deg, #5941CC 0%, #5A5ED7 12%, #5C85E5 31%, #5DA4F0 49%, #5EBAF8 67%, #5FC7FD 84%, #5FCCFF 100%);
	}
	.two-item-jiantou {
		width: 0.14rem;
		height: 0.99rem;
		margin: 0.1rem auto;
		background-image: url(../../assets/images/learn-jiantou.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.two-item-right {
		text-align: left;
		margin-left: 0.25rem;
	}
	.right-title {
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #43E9FF;
		line-height: 0.40rem;
	}
	.right-text {
		font-size: 0.26rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		line-height: 0.38rem;
		margin-top: 0.06rem;
	}
	.three-header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		/* justify-content: center; */
	}
	.three-title {
		width: 4.08rem;
		height: 1.13rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.34rem;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #F2CF5F;
		background-image: url(../../assets/images/learn-case.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.three-header-desc {
		width: 1.99rem;
		height: 0.5rem;
		margin-left: 0.12rem;
		background-image: url(../../assets/images/learn-contrast.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-bottom: 0.1rem;
	}
	.three-desc {
		font-size: 0.24rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		line-height: 0.33rem;
		margin-top: 0.06rem;
		/* padding: 0 0.66rem; */
		text-align: left;
	}
	.three-list {
		width: 100%;
		/* padding: 0 0.65rem; */
		margin-top: 0.27rem;
	}
	.three-item {
		width: 100%;
		margin-bottom: 0.38rem;
		border: 2px solid rgba(29,223,255, 0.2);
	}
	.three-item .item-header  {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 0.86rem;
		font-size: 0.32rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #3ED6FF;
		border-bottom: 1px solid rgba(207,246,255, 0.36);
	}
	.three-item .item-content {
		padding: 0.1rem 0.32rem 0.4rem;
	}
	.three-item .item-cell {
		width: 100%;
		height: 0.68rem;
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		text-align: left;
		display: inline-block;
		white-space: nowrap; 
		overflow: hidden;
		text-overflow:ellipsis;
		line-height: 0.80rem;
		border-bottom: 1px solid rgba(207,246,255, 0.36);
	}
	.item-cell2 {
		width: 100%;
		height: 0.68rem;
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		text-align: left;
		display: inline-block;
		white-space: nowrap; 
		overflow: hidden;
		text-overflow:ellipsis;
		line-height: 0.80rem;
	}
	.item-cell3 {
		width: 100%;
		height: 0.68rem;
		font-size: 0.24rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #3ED6FF;
		text-align: right;
		line-height: 0.80rem;
	}
	.padding-bottom-0 {
		padding-bottom: 0.1rem !important;
	}
	.learn-last {
		width: 100%;
		margin-top: 0.92rem;
		padding: 0 0.69rem;
	}
	.last-one {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 1.59rem;
		font-size: 0.34rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #F2CF5F;
		line-height: 0.48rem;
		background-image: url(../../assets/images/learn-foot.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.last-desc {
		font-size: 0.24rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		line-height: 0.38rem;
		margin-top: 0.2rem;
	}
	.footer {
		font-size: 0.20rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8C9AA5;
		line-height: 0.28rem;
		margin-top: 1.2rem;
	}
</style>
<style lang="scss" scoped>
	
.van-tabbar{
	height: 0.98rem;
	
	span{
		font-size: 0.2rem;
	}
	img{
		width: 0.43rem;
		height: 0.44rem;
	}
}
</style>